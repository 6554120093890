import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/base.js";
import { Accordion, AccordionItem, AccordionHeading, AccordionPanel } from "@reusable";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className="container animate-on-enter-1" style={{
      marginBottom: '40px'
    }}>
      <Accordion collapsible mdxType="Accordion">
        <AccordionItem mdxType="AccordionItem">
  <AccordionHeading mdxType="AccordionHeading">
    Benefits Investigation
  </AccordionHeading>
  <AccordionPanel mdxType="AccordionPanel">
            <p><strong>ACCESS4Me will conduct a patient-specific benefit verification to help:</strong></p>
            <ul>
              <li parentName="ul">{`Determine if ROLVEDON`}<sup style={{
                  "fontSize": "100%"
                }}>{`®`}</sup>{` is a covered product`}</li>
              <li parentName="ul">{`Review medical policy guidelines (if applicable) associated with the patient’s plan requirements`}</li>
              <li parentName="ul">{`Provide information and resources on the prior authorization (PA) process, when applicable`}</li>
              <li parentName="ul">{`Assist with patient financial considerations`}</li>
            </ul>
            <p>{`A detailed Benefits Summary Report will provide the patient's coverage, out-of-pocket requirements, and any financial assistance options available.`}{`*`}</p>
            <p className="text-small hang-footnote">
  *Dependent upon receipt of a completed and signed enrollment form via fax or
  provider portal.
            </p>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem mdxType="AccordionItem">
  <AccordionHeading mdxType="AccordionHeading">
    Prior Authorization Assistance
  </AccordionHeading>
  <AccordionPanel mdxType="AccordionPanel">
            <p>
  <strong>
    If prior authorization (PA) is required, ACCESS4Me will assist with:
  </strong>
            </p>
            <ul>
              <li parentName="ul">{`Obtaining PA forms for ROLVEDON`}</li>
              <li parentName="ul">{`Identifying payor requirements and communicating to the practice`}</li>
              <li parentName="ul">{`Tracking PA status with the payor`}</li>
              <li parentName="ul">{`PA appeals information and resources, if necessary`}</li>
            </ul>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem mdxType="AccordionItem">
  <AccordionHeading mdxType="AccordionHeading">
    Billing and Coding Information
  </AccordionHeading>
  <AccordionPanel mdxType="AccordionPanel">
            <p><strong>
  ACCESS4Me can help address questions up front, including<sup>†</sup>:
              </strong></p>
            <ul>
              <li parentName="ul">{`Information on diagnosis and product codes`}</li>
              <li parentName="ul">{`Information on coverage, coding, and reimbursement`}</li>
              <li parentName="ul">{`Tracking and monitoring of claim status after submission`}</li>
              <li parentName="ul">{`Help understanding payment details`}</li>
            </ul>
            <p className="text-small hang-footnote">
  <sup>†</sup>ACCESS4Me cannot guarantee reimbursement or claims adjudication.
  Please note that it is the sole responsibility of the provider to select
  proper coding for rendered products or services and to ensure the accuracy of
  all claims used in seeking reimbursement.
            </p>
          </AccordionPanel>
        </AccordionItem>
        <AccordionItem mdxType="AccordionItem">
  <AccordionHeading mdxType="AccordionHeading">
    Appeals Information and Resources
  </AccordionHeading>
  <AccordionPanel mdxType="AccordionPanel">
            <p>
  <strong>
    For underpaid or denied claims, ACCESS4Me offers support with:
  </strong>
            </p>
            <ul>
              <li parentName="ul">{`Verifying the reason for the underpayment or denial`}</li>
              <li parentName="ul">{`Clarifying the payor appeals process and related requirements`}</li>
              <li parentName="ul">{`Information on appeals submission based on payor requirements`}</li>
              <li parentName="ul">{`Tracking appeal status with the payor`}</li>
              <li parentName="ul">{`Providing information on pursuing all levels of appeal, as needed`}</li>
              <li parentName="ul">{`Documents and forms, including template letters for medical necessity, appeal, tiering exceptions, and more`}</li>
            </ul>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      